import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    this.h = this.handleClicks.bind(this);
  }

  openDropdown(event) {
    event.stopPropagation();
    this.createDropdown(event);
  }

  handleClicks() {
    this.destroyDropdown();
    document.removeEventListener('click', this.h);
  }

  destroyDropdown() {
    this.menuTarget.classList.add('hidden');
    this.menuTarget.classList.remove('block');
    this.dropdownMenu.destroy();
  }

  createDropdown(event) {
    let element = event.target;
    while (element.nodeName !== 'A') {
      element = element.parentNode;
    }
    this.dropdownMenu = createPopper(element, this.menuTarget, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ],
    });
    this.menuTarget.classList.add('block');
    this.menuTarget.classList.remove('hidden');

    // listen for clicks so we can close the dropdown
    document.addEventListener('click', this.h);
  }
}
