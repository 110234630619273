import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /* Sidebar - Side navigation menu on mobile/responsive mode */
  toggleNavbar() {
    const collapseID = 'example-collapse-sidebar';
    document.getElementById(collapseID).classList.toggle('hidden');
    document.getElementById(collapseID).classList.toggle('bg-white');
    document.getElementById(collapseID).classList.toggle('m-2');
    document.getElementById(collapseID).classList.toggle('py-3');
    document.getElementById(collapseID).classList.toggle('px-6');
  }
}
