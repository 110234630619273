import { Controller } from '@hotwired/stimulus';
import WebauthnRegistration from '../../api_client/webauthn_registration';

export default class extends Controller {
  static targets = ['nickname'];

  click(e) {
    e.preventDefault();
    new WebauthnRegistration().challenge(this.nicknameTarget.value);
  }
}
