import { post } from '@rails/request.js';
import * as WebAuthnJSON from '@github/webauthn-json';

export default class WebauthnAuthentication {
  challenge() {
    try {
      if (!WebAuthnJSON.supported()) {
        return;
      }

      post('/webauthn/authentications/challenge.json').then((e) => {
        e.response.text().then((body) => {
          this.authenticate(JSON.parse(body));
        });
      });
    } catch (error) {
      console.log('[WebauthnAuthentication] failed in challenge', error);
    }
  }

  async authenticate(data) {
    try {

      const credential = await WebAuthnJSON.get({ publicKey: data });
      credential['challenge'] = data['challenge'];

      const response = await post('/webauthn/authentications', {
        body: JSON.stringify(credential),
      });

      if (response.ok) {
        const data = await response.json;
        window.location = data.redirect;
      } else {
        console.log('[WebauthnAuthentication] authentication response was not ok', response);
      }
    } catch (error) {
      console.log('[WebauthnAuthentication] authentication failed', error);
    }
  }
}