import { post } from '@rails/request.js';
import * as WebAuthnJSON from '@github/webauthn-json';

export default class WebauthnRegistration {
  challenge(nickname) {
    try {
      const body = JSON.stringify({ credential: { nickname: nickname }});
      post('/webauthn/credentials/challenge.json', { body: body }).then((r) => {
        r.response.text().then((body) => {
          this.create(JSON.parse(body), nickname);
        });
      });
    } catch (error) {
      console.log('[WebauthnRegistration] failed in challenge', error);
    }
  }

  async create(data, nickname) {
    try {
      const credential = await WebAuthnJSON.create({ publicKey: data });
      const url = `/webauthn/credentials?nickname=${nickname}`;
      await post(url, {
        body: JSON.stringify(credential),
      });

      window.location = '/';
    } catch (error) {
      console.log('something is wrong', error);
    }
  }
}